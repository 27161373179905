<template>
  <div>
    <section class="extendedmatch" v-if="data">
      <div class="extendedmatch__head">
        <div class="extendedmatch__head__live" v-if="isLive">
          <div></div>
          <div>En vivo</div>
        </div>
        <div v-else-if="data.game_status === 4" class="extendedmatch__head__hour">
          {{ data.schedule.substring(0, 5) }} HRS
        </div>
        <div v-else class="extendedmatch__head__finished">Finalizado</div>
      </div>
      <div class="extendedmatch__body">
        <div class="extendedmatch__body__date-cont">
          <div class="date-item">{{ formatedDayDate(data.date) }}&nbsp;{{ formatedMonthDate(data.date) }}</div>
        </div>
        <div class="extendedmatch__body__game-cont">
          <div class="extendedmatch__body__game-cont__team-cont-left">
            <div class="extendedmatch__body__game-cont__team-cont-left__name">
              {{ data.home_team.colloquial_name }}
            </div>
            <img class="extendedmatch__body__game-cont__team-cont-left__img" :src="data.home_team.logo" />
          </div>
          <div class="extendedmatch__body__game-cont__score">
            <template v-if="isPostMatch || isLive">
              <span
                class="extendedmatch__body__game-cont__score__penalty"
                v-if="data.penalties_score_home_team != 0 || data.penalties_score_visiting_team != 0"
                >({{ data.penalties_score_home_team }})
              </span>
              &nbsp;<span class="extendedmatch__body__game-cont__score__scorenum"
                >{{ data.score[0] }} · {{ data.score[1] }}</span
              >
              <span
                class="extendedmatch__body__game-cont__score__penalty"
                v-if="data.penalties_score_home_team != 0 || data.penalties_score_visiting_team != 0"
              >
                ({{ data.penalties_score_visiting_team }})</span
              >
            </template>
            <template v-else>VS</template>
          </div>
          <div class="extendedmatch__body__game-cont__team-cont-right">
            <img class="extendedmatch__body__game-cont__team-cont-right__img" :src="data.visiting_team.logo" />
            <div class="extendedmatch__body__game-cont__team-cont-right__name">
              {{ data.visiting_team.colloquial_name }}
            </div>
          </div>
        </div>
        <div class="extendedmatch__body__bottom-cont">
          <div class="date-item disable">{{ formatedDayDate(data.date) }}&nbsp;{{ formatedMonthDate(data.date) }}</div>
          <div
            class="buttom-double-cont"
            v-if="
              isPostMatch &&
                (data.minutes.home_goals_minutes.length > 0 ||
                  data.minutes.visiting_goals_minutes.length > 0 ||
                  data.minutes.home_yellow_cards_minutes.length > 0 ||
                  data.minutes.away_yellow_cards_minutes.length > 0 ||
                  data.minutes.home_red_cards_minutes.length > 0 ||
                  data.minutes.away_red_cards_minutes.length > 0)
            "
          >
            <div class="buttom-double-cont__buttom-stats" @click="onGameClicked">Ver</div>
            <div class="buttom-double-cont__buttom-open-info" @click="clickOpenExtraInfo">
              <img v-if="!openExtraInfo" src="/images/arrow-green-up.png" />
              <img v-else src="/images/arrow-green-down.png" />
            </div>
          </div>
          <div v-else class="buttom-simple" @click="onGameClicked">Ver <img src="/images/arrow-green.png" /></div>
        </div>
      </div>
      <ExtraDataMatch v-if="isPostMatch && openExtraInfo" :data="data.minutes" />
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'ExtendedMatch',
  components: { ExtraDataMatch: () => import('@/components/Sections/Calendar/ExtraDataMatch') },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      flag: false,
      openExtraInfo: false,
    };
  },
  computed: {
    isLive() {
      return this.data.game_status === 4 && this.data.is_live == 1;
    },
    isPostMatch() {
      return this.data.game_status === 1 || this.data.game_status === 0;
    },
  },
  methods: {
    ...mapActions('routes', ['goToGame']),
    clickOpenExtraInfo() {
      this.openExtraInfo = !this.openExtraInfo;
    },
    formatedDayDate(date) {
      const dateFormat = new Date(date);
      const dayOfWeek = dateFormat.getUTCDate();
      return dayOfWeek;
    },
    formatedMonthDate(date) {
      const dateFormat = new Date(date);
      const month = new Intl.DateTimeFormat('es-ES', { month: 'short', timeZone: 'UTC' })
        .format(dateFormat)
        .split('.')[0];

      return month;
    },
    onGameClicked() {
      this.goToGame({
        name: 'matchAnalysis',
        gameId: this.data.game_id,
        currentSeasonId: this.data.season_id,
        hideHeader: 1,
        page: 'calendar',
        isCup: this.isCup,
        backRoute: this.$route.name,
      });
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.disable {
  display: none;
  @media screen and (max-width: 660px) {
    display: inline-block;
  }
}
.buttom-double-cont {
  display: inline-flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: auto;
  margin-right: 16px;
  height: 30px;
  margin-left: 10px;
  @media screen and (max-width: 740px) {
    margin-right: 6px;
  }
  @media screen and (max-width: 660px) {
    width: 48%;
    margin-top: 2px;
  }

  &__buttom-stats {
    width: 65px;
    height: 30px;
    font-size: 17px;
    font-weight: normal;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.34px;
    text-align: center;
    color: #cbee6d;
    border-radius: 6px;
    background-color: #132739;
    @media screen and (max-width: 660px) {
      width: 70%;
    }
    @media screen and (max-width: 530px) {
      font-size: 16px;
    }
    @media screen and (max-width: 480px) {
      font-size: 15px;
    }
    @media screen and (max-width: 400px) {
      font-size: 14px;
    }
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
  &__buttom-open-info {
    width: 42px;
    height: 28px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: #132739;
    margin-left: 8px;
    @media screen and (max-width: 660px) {
      width: 25%;
    }
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
.buttom-simple {
  width: 75px;
  height: 30px;
  border-radius: 6px;
  background-color: #132739;
  font-family: 'Avenir-Demi';
  font-size: 17px;
  font-weight: normal;
  display: inline-flex;
  margin-right: 16px;
  justify-content: center;
  align-content: center;

  align-items: center;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.34px;
  text-align: center;
  color: #cbee6d;
  @media screen and (max-width: 740px) {
    margin-right: 6px;
  }
  @media screen and (max-width: 660px) {
    width: 48%;
    margin-top: 2px;
    margin-right: 6px;
  }
  @media screen and (max-width: 530px) {
    font-size: 16px;
  }
  @media screen and (max-width: 500px) {
    width: 47%;
  }
  @media screen and (max-width: 480px) {
    font-size: 15px;
  }
  @media screen and (max-width: 400px) {
    font-size: 14px;
    width: 46%;
  }
  @media screen and (max-width: 330px) {
    width: 45%;
  }
  img {
    @media screen and (max-width: 660px) {
      margin-left: 5px;
    }
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.date-item {
  width: 83px;
  height: 30px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-transform: uppercase;
  line-height: 30px;
  border-radius: 6px;
  margin-left: 22px;
  border: solid 1px #d4dde5;
  letter-spacing: normal;
  text-align: center;
  font-family: 'Avenir-Demi';
  color: #5b6c7a;
  @media screen and (max-width: 740px) {
    margin-left: 8px;
  }
  @media screen and (max-width: 660px) {
    width: 48%;
    margin-top: 2px;
  }
  @media screen and (max-width: 480px) {
    font-size: 11px;
  }
  @media screen and (max-width: 400px) {
    font-size: 10px;
  }
}
.extendedmatch {
  width: 100%;
  max-width: 1247px;
  height: auto;
  margin: 0 auto;
  margin-top: 12px;
  border-radius: 6px;
  border: solid 1px #e1e3e7;

  display: inline-block;
  @media screen and (max-width: 1247px) {
    width: 98%;
  }
  @media screen and (max-width: 580px) {
    width: 96%;
  }
  @media screen and (max-width: 660px) {
    min-height: 109px;
  }
  &__head {
    width: 100%;
    height: 16.5px;
    display: flex;
    justify-content: center;
    &__live {
      width: 45px;
      height: 15px;
      display: flex;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 15px;
      letter-spacing: -0.16px;
      text-align: center;
      color: #ffffff;
      border-radius: 5px;
      background-color: #132739;
      font-family: 'Avenir-Medium';
      font-size: 8px;
      margin-top: 4px;
      & > div:first-child {
        margin-top: 4px;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background-color: #cbee6d;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
    &__hour {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      margin-top: 4px;
      line-height: 18px;
      font-family: 'Avenir-Demi';
      letter-spacing: normal;
      text-align: center;
      color: #6e7c89;
      @media screen and (max-width: 400px) {
        font-size: 11px;
      }
    }
    &__finished {
      font-size: 12px;
      font-weight: normal;
      font-family: 'Avenir-Demi';
      font-stretch: normal;
      font-style: normal;
      margin-top: 3px;
      line-height: 18px;
      letter-spacing: normal;
      text-align: center;
      color: #6e7c89;
      @media screen and (max-width: 400px) {
        font-size: 11px;
      }
    }
  }
  &__body {
    width: 100%;
    height: 46.5px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 660px) {
      display: inline-block;
    }
    &__date-cont {
      width: 26.22%;
      height: 46.5px;
      @media screen and (max-width: 1020px) {
        width: 20%;
      }
      @media screen and (max-width: 740px) {
        width: 18.5%;
      }
      @media screen and (max-width: 660px) {
        display: none;
      }
    }
    &__game-cont {
      width: 46.75%;
      height: 46.5px;
      display: inline-flex;
      justify-content: center;
      @media screen and (max-width: 1020px) {
        width: 59.19%;
      }
      @media screen and (max-width: 740px) {
        width: 62.19%;
      }
      @media screen and (max-width: 660px) {
        width: 100%;
      }
      &__team-cont-right {
        width: 37.79%;
        height: 46.5px;
        display: inline-flex;
        justify-content: left;
        align-items: center;
        align-content: center;
        &__name {
          width: 180px;
          text-align: left;
          margin-left: 9px;
          font-family: 'Avenir-Medium';
          font-size: 17px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.06;
          letter-spacing: -0.34px;
          color: #132739;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          @media screen and (max-width: 660px) {
            margin-left: 4px;
          }
          @media screen and (max-width: 530px) {
            font-size: 16px;
          }
          @media screen and (max-width: 480px) {
            font-size: 15px;
          }
          @media screen and (max-width: 400px) {
            font-size: 14px;
            width: 65px;
          }
        }
        &__img {
          width: 28px;
          margin-left: 5px;
        }
      }
      &__team-cont-left {
        width: 37.79%;
        height: 46.5px;
        display: inline-flex;
        justify-content: right;
        align-items: center;
        align-content: center;
        &__name {
          width: 180px;
          text-align: right;
          margin-right: 9px;
          font-size: 17px;
          font-family: 'Avenir-Medium';
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.06;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          letter-spacing: -0.34px;
          color: #132739;
          @media screen and (max-width: 660px) {
            margin-right: 4px;
          }
          @media screen and (max-width: 530px) {
            font-size: 16px;
          }
          @media screen and (max-width: 480px) {
            font-size: 15px;
          }
          @media screen and (max-width: 400px) {
            font-size: 14px;
            width: 65px;
          }
        }
        &__img {
          width: 28px;
          margin-right: 5px;
        }
      }
      &__score {
        width: 20.82%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        font-family: 'Circular-Std';
        font-size: 23px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.78;
        letter-spacing: -0.46px;
        text-align: center;
        color: #000;
        margin-left: 1.87%;
        margin-right: 1.87%;
        @media screen and (max-width: 1020px) {
          width: 18.56%;
          margin-left: 0.37%;
          margin-right: 0.37%;
        }
        @media screen and (max-width: 740px) {
          width: 24.56%;
        }
        &__penalty {
          font-size: 15px;
          margin-left: 5px;
          margin-right: 5px;
          @media screen and (max-width: 1180px) {
            font-size: 12px;
            margin-left: 4px;
            margin-right: 4px;
          }
          @media screen and (max-width: 760px) {
            font-size: 11px !important;
            margin-left: 3px;
            margin-right: 3px;
          }
          @media screen and (max-width: 740px) {
            font-size: 13px !important;
            margin-left: 4px;
            margin-right: 4px;
          }
          @media screen and (max-width: 660px) {
            font-size: 15px !important;
            margin-left: 5px;
            margin-right: 5px;
          }
          @media screen and (max-width: 470px) {
            font-size: 13px !important;
            margin-left: 3px;
            margin-right: 3px;
          }
          @media screen and (max-width: 380px) {
            font-size: 12px !important;
            margin-left: 2px;
            margin-right: 2px;
          }
        }
        &__scorenum {
          @media screen and (max-width: 1180px) {
            font-size: 21px !important;
          }
          @media screen and (max-width: 820px) {
            font-size: 19px !important;
            margin-top: 2px;
          }
          @media screen and (max-width: 780px) {
            font-size: 18px !important;
            margin-top: -2px;
          }
          @media screen and (max-width: 760px) {
            font-size: 17px !important;
            margin-top: -2px;
          }
          @media screen and (max-width: 740px) {
            font-size: 23px !important;
            margin-top: -1px;
          }

          @media screen and (max-width: 470px) {
            font-size: 20px !important;
            margin-top: 2px;
          }
          @media screen and (max-width: 380px) {
            font-size: 17px !important;
            margin-top: 0px;
          }
          @media screen and (max-width: 330px) {
            font-size: 16px !important;
            margin-top: 0px;
          }
        }
      }
    }
    &__bottom-cont {
      width: 26.38%;
      height: 46.5px;
      display: inline-flex;
      justify-content: right;
      @media screen and (max-width: 1020px) {
        width: 20.16%;
      }
      @media screen and (max-width: 740px) {
        width: 18.66%;
      }
      @media screen and (max-width: 660px) {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}
</style>
