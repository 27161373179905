import { render, staticRenderFns } from "./ExtendedMatch.vue?vue&type=template&id=708b316b&scoped=true&"
import script from "./ExtendedMatch.vue?vue&type=script&lang=js&"
export * from "./ExtendedMatch.vue?vue&type=script&lang=js&"
import style0 from "./ExtendedMatch.vue?vue&type=style&index=0&id=708b316b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "708b316b",
  null
  
)

export default component.exports